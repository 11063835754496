import { Component, ComponentProps } from "solid-js";
import { Checkbox as KCheckbox } from "@kobalte/core";
import { Icon } from "solid-heroicons";
import { check } from "solid-heroicons/outline";

type KCheckboxProps = ComponentProps<typeof KCheckbox.Root>;

type CheckBoxProps = Pick<
  KCheckboxProps,
  "name" | "defaultChecked" | "checked" | "onChange" | "disabled"
> & {
  label?: string;
  rootClass?: string;
  controlClass?: string;
  labelClass?: string;
};

export const Checkbox: Component<CheckBoxProps> = (props) => {
  const rootClass = () => {
    const base = `inline-flex items-center ${
      props.disabled ? "cursor-not-allowed" : ""
    }`;
    return `${base} ${props.rootClass ?? ""}`;
  };

  const controlClass = () => {
    const base = "w-5 h-5 aspect-square rounded-md border-2 border-gray-400 ";

    const checked =
      "data-[checked]:border-roma-blue data-[checked]:bg-roma-blue data-[checked]:text-white";

    const focus =
      "peer-focus-visible:outline peer-focus-visible:outline-offset-2 peer-focus-visible:outline-roma-blue";
    return `${base} ${checked} ${focus} ${props.controlClass ?? ""}`;
  };

  const labelClass = () => {
    const base = "text-sm text-roma-dark-grey select-none ml-2";
    return `${base} ${props.labelClass ?? ""}`;
  };

  return (
    <KCheckbox.Root class={rootClass()} {...props}>
      <KCheckbox.Input class="peer" />
      <KCheckbox.Control class={controlClass()}>
        <KCheckbox.Indicator>
          <Icon path={check} class="w-4 h-4" stroke-width={4} />
        </KCheckbox.Indicator>
      </KCheckbox.Control>
      <KCheckbox.Label class={labelClass()}>{props.label}</KCheckbox.Label>
    </KCheckbox.Root>
  );
};
